<template>
    <div>注销</div>
</template>

<script>
export default {
  mounted () {
    this.$store
      .dispatch('oauth/resetToken')
      .then(() => {})
      .catch(() => {})
  }
}
</script>

<style>
</style>
